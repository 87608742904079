import About from "./Components/About";
import Booking from "./Components/Booking";
import Carousel from "./Components/Carousel";
import ExploreServices from "./Components/ExploreServices";
import Fact from "./Components/Fact";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar";
import Service from "./Components/Service";
import Team from "./Components/Team";
import Testimonial from "./Components/Testimonial";
import TopBar from "./Components/TopBar";
import Works from "./NavComponents/Works";
import Recommended from "./Components/Recommended";
import WhatsappIcon from "./Components/WhatsappIcon";

function Home() {
  return (
    <div>
      {/* <TopBar /> */}
      <NavBar />
      <Carousel />
      <Recommended />
      <Service />
      <About />
      <Fact />
      <ExploreServices />
      <Booking />
      {/* <Team /> */}
      <Works />
      <Testimonial />

      <Footer />

      {/* <!-- Back to Top --> */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>

      {/* <!-- JavaScript Libraries --> */}
      <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
      <script src="lib/wow/wow.min.js"></script>
      <script src="lib/easing/easing.min.js"></script>
      <script src="lib/waypoints/waypoints.min.js"></script>
      <script src="lib/counterup/counterup.min.js"></script>
      <script src="lib/owlcarousel/owl.carousel.min.js"></script>
      <script src="lib/tempusdominus/js/moment.min.js"></script>
      <script src="lib/tempusdominus/js/moment-timezone.min.js"></script>
      <script src="lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js"></script>

      {/* <!-- Template Javascript --> */}
      <script src="js/main.js"></script>
    </div>
  );
}

export default Home;
