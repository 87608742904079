import React from 'react'
import { Link } from "react-router-dom";

const WhatsappIcon = () => {
  return (
    <div>
        <Link to="https://wa.me/918100098024"><img style={{
          position: "fixed",
          right: "43px",
          bottom: "114px",
          zIndex: "900",
        }} src="img/whatsapp-873316_1280.png" width="50" alt='...' /></Link>
      </div>
  )
}

export default WhatsappIcon
